<template>
  <div class="container-fluid py-3">
    <div class="row justify-content-center">
      <div class="col-12">
        <h5 class="text-center">{{ $t('matchesBet') }}</h5>
        <hr>
        <b-table striped bordered hover :items="matches.data" :fields="columns">
          <div slot="local" class="text-center" slot-scope="row">
            <router-link v-if="row.item.user_a_slug" :to="{ name: 'PlayerHome', params: { lang: lang, console: console, playerSlug: row.item.user_a_slug } }">{{ row.item.user_a_nick }}</router-link>
            <router-link v-else-if="isAuthenticated" class="btn btn-primary" :to="{ name: 'VsEntry', params: { lang: lang, console: console, matchId: row.item.id, position: 'local' } }">{{ $t('entry') }}</router-link>
          </div>
          <div slot="visitor" class="text-center" slot-scope="row">
            <router-link v-if="row.item.user_b_slug" :to="{ name: 'PlayerHome', params: { lang: lang, console: console, playerSlug: row.item.user_b_slug } }">{{ row.item.user_b_nick }}</router-link>
            <router-link v-else-if="isAuthenticated" class="btn btn-primary" :to="{ name: 'VsEntry', params: { lang: lang, console: console, matchId: row.item.id, position: 'visitor' } }">{{ $t('entry') }}</router-link>
          </div>
          <template slot="actions" slot-scope="row">
            <div class="w-100 text-center">
              <a :href="row.item.url" target="_blank" class="btn btn-primary">
                  <i class="fa fa-facebook px-1"></i>
              </a>
              <router-link class="btn btn-primary mx-1" :to="{ name: 'VsDetail', params: { lang: lang, console: console, matchId: row.item.id } }"><i class="fa fa-eye px-1"></i></router-link>
              <router-link v-if="isAuthenticated && (user.id === row.item.user_a_id || user.id === row.item.user_b_id)" class="btn btn-primary mx-1" :to="{ name: 'VsEdit', params: { lang: lang, console: console, matchId: row.item.id } }"><i class="fa fa-pencil px-1"></i></router-link>
            </div>
          </template>
        </b-table>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      matches: [],
      columns: [
        { key: 'local', label: 'Local', sort: false },
        { key: 'visitor', label: this.$t('visitor'), sort: false },
        { key: 'bet', label: this.$t('entry'), sort: false },
        { key: 'gain', label: this.$t('gain'), sort: false },
        { key: 'actions', label: '', sort: false }
      ]
    }
  },
  computed: {
    ...mapGetters([
      'console',
      'lang',
      'user',
      'isAuthenticated'
    ])
  },
  mounted () {
    this.fetchData()
  },
  methods: {
    fetchData () {
      const path = `${this.lang}/console/${this.console}/shop/bets/vs`
      this.$axios.get(path).then(response => {
        this.matches = response.data.data
      })
    }
  }
}
</script>
